import Service from '@/helpers/service'

export default {
	get(params) {
		return Service().get('McrStatusDashboard', {params});
	},
	approved(params) {
		return Service().get('McrStatusDashboard/all-approved', {params});
	},
	totalMcr(params) {
		return Service().get('McrStatusDashboard/all-total-mcr', {params});
	},
	totalItemMcr(params) {
		return Service().get('McrStatusDashboard/all-total-item-mcr', {params});
	},
}
