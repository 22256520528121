<template>
    <div>
    	<div v-if="authUserPermission['mcr-status-view']">
	        <base-header class="pb-6">
	            <div class="row align-items-center py-4">
	                <div class="col-lg-6 col-7">
	                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
	                </div>
	            </div>
	        </base-header>
	        <div class="container-fluid mt--7">
	            <div class="row mt--5">
	                <div class="col-xl-4 col-md-6">
	                    <stats-card :title="tt('approved')" icon="fa fa-check" :sub-title="total.approved" type="success">
	                    </stats-card>
	                </div>
	                <div class="col-xl-4 col-md-6">
	                    <stats-card :title="tt('total_mcr')" icon="fa fa-chart-line" :sub-title="total.totalMcr" type="primary">
	                    </stats-card>
	                </div>
	                <div class="col-xl-4 col-md-6">
	                    <stats-card :title="tt('total_item_mcr')" icon="ni ni-chart-bar-32" :sub-title="total.totalItemMcr" type="warning">
	                    </stats-card>
	                </div>
	            </div>

	            <div class="card">
	                <div class="card-header">
	                    <div class="row">
	                        <div class="col-4">
	                            <base-input :name="tt('company_code')" v-if="type == 'A000'">
	                                <el-select v-model="company_code" :placeholder="tt('company_code')" @change="onChange($event)">
	                                    <el-option class="select-danger" value="" :label="tt('all_company_code')"></el-option>
	                                    <el-option class="select-danger" :value="row.company_code" :label="row.company_code+' - '+row.company_description" :key="row.company_code" v-for="row in companyList"></el-option>
	                                </el-select>
	                            </base-input>
	                        </div>
	                        <div class="col-8 text-right" v-if="type == 'A000'">
	                            <a v-if="authUserPermission['mcr-status-export']" :href="apiUrl + 'McrStatusDashboard/export?token=' + token" class="btn btn-sm btn-outline-dark">
	                                {{ tt('export_as_excel') }}
	                            </a>
	                        </div>
	                    </div>
	                </div>
	                <div class="table-responsive" style="height: 470px;">
		                <table class="table" v-if="!onLoad.table">
		                    <thead>
		                        <tr>
		                            <th class="text-center" colspan="9">{{ date }}</th>
		                        </tr>
		                        <tr>
		                            <th>{{ tt('company_code') }}</th>
		                            <th class="text-center">{{ tt('approved') }}</th>
		                            <th class="text-center">{{ tt('rejected') }}</th>
		                            <th class="text-center">{{ tt('on_process') }}</th>
		                            <th class="text-center">{{ tt('total_mcr') }}</th>
		                            <th class="text-center">{{ tt('total_item_mcr') }}</th>
		                            <th class="text-center">{{ tt('total_create') }}</th>
		                            <th class="text-center">{{ tt('total_change') }}</th>
		                            <th class="text-center">{{ tt('total_extend') }}</th>
		                            <th class="text-center">{{ tt('total_deletion') }}</th>
		                            <th class="text-center">{{ tt('total_master_cataloguing') }}</th>
		                            <th class="text-center">{{ tt('total_konsolidation') }}</th>
		                        </tr>
		                    </thead>
		                    <tbody>
		                        <tr v-for="(row, index) in table.data">
		                            <td>{{row.company_code}}</td>
		                            <td class="text-center">{{row.approved}}</td>
		                            <td class="text-center">{{row.rejected}}</td>
		                            <td class="text-center">{{row.on_process}}</td>
		                            <td class="text-center">{{row.total_mcr}}</td>
		                            <td class="text-center">{{row.total_item_mcr}}</td>
		                            <td class="text-center">{{row.total_create}}</td>
		                            <td class="text-center">{{row.total_change}}</td>
		                            <td class="text-center">{{row.total_extend}}</td>
		                            <td class="text-center">{{row.total_deletion}}</td>
		                            <td class="text-center">{{row.total_master_cataloguing}}</td>
		                            <td class="text-center">{{row.total_konsolidation}}</td>
		                        </tr>
		                    </tbody>
		                </table>
	                	<skeleton-loading v-else/>
	                </div>
	            </div>
	        </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
	import {mapState} from "vuex";
	import Api from '@/helpers/api';
	import mcrStatusDasboard from '@/services/dashboard/mcrStatusDashboard.service';
	import companyCode from '@/services/master/companyCode.service';
	import config from '@/configs/config';

	export default {
		data() {
			return {
				date: '',
                total: {
					approved: '0',
                    totalMcr: '0',
                    totalItemMcr: '0'
                },
				table: {
					data: []
				},
				onLoad: {
					table: true
				},
                companyList: [],
                company_code: '',
                type: '',
				apiUrl: config.apiUrl,
				token: localStorage.getItem('token')
			}
		},
		computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
		},
		mounted() {
			const today = new Date();
			this.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
			this.companies();
			this.get();
			this.approved();
			this.totalMcr();
			this.totalItemMcr();
		},
		methods: {
			onChange(val) {
				this.onLoad.table = true;
				this.get(val);
				this.approved(val);
				this.totalMcr(val);
				this.totalItemMcr(val);
			},
			companies() {
				let context = this;
				Api(context, companyCode.get()).onSuccess(function(response) {
					context.companyList = response.data.data.data.data;
				}).onError(function(error) {
					context.companyList = [];
				}).call()
            },
			get(param = '') {
				let context = this;
				Api(context, mcrStatusDasboard.get({search: param})).onSuccess(function(response) {
					context.table.data = response.data.data.companies.data;
					context.type       = response.data.data.pi;
					context.onLoad.table = false;
				}).onError(function(error) {
					context.table.data = [];
					context.onLoad.table = false;
				}).call()
			},
			approved(param = '') {
				let context = this;
				Api(context, mcrStatusDasboard.approved({search: param})).onSuccess(function(response) {
					context.total.approved = response.data.toString();
				}).onError(function(error) {
					context.total.approved = 0;
				}).call()
			},
			totalMcr(param = '') {
				let context = this;
				Api(context, mcrStatusDasboard.totalMcr({search: param})).onSuccess(function(response) {
					context.total.totalMcr = response.data.toString();
				}).onError(function(error) {
					context.total.approved = 0;
				}).call()
			},
			totalItemMcr(param = '') {
				let context = this;
				Api(context, mcrStatusDasboard.totalItemMcr({search: param})).onSuccess(function(response) {
					context.total.totalItemMcr = response.data.toString();
				}).onError(function(error) {
					context.total.totalItemMcr = 0;
				}).call()
			},
		}
	};
</script>
